
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiBaseUrl:'https://api.sg-erp.com/api/v1/sgew/'
  // apiBaseUrl:'http://localhost:8001/api/v1/sgew/'
};
// sudo hostnamectl set-hostname sgew-test.localdomain
// vi /etc/hosts