<div class="grid-buttons">
  <mat-form-field class="grid-button" appearance="fill">
    <mat-label> Search </mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Type here" #input>
  </mat-form-field>

  <mat-form-field class="grid-button file-export-button" appearance="fill">
    <mat-label><i class="fa-solid fa-file-export"></i></mat-label>
    <mat-select>
      <mat-option>
        <button mat-button (click)="exporter.exportTable('xlsv')">
          Excel
        </button>
      </mat-option>
      <mat-option>
        <button mat-button (click)="exporter.exportTable('json')">
          JSON
        </button>
      </mat-option>
      <mat-option>
        <button mat-button (click)="exporter.exportTable('csv')">
          CSV
        </button>
      </mat-option>
      <mat-option>
        <button mat-button (click)="exporter.exportTable('txt')">
          TXT
        </button>
      </mat-option>
      <mat-option>
        <button mat-button (click)="exportToXml()">
          XML
        </button>
      </mat-option>
      <mat-option>
        <button mat-button (click)="exportToSql()">
          SQL
        </button>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
