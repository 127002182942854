import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { IdData, TableColDef } from '../../../pages/models/common.model';
import { CommonUtilityService } from '../../service/common-utility.service';

@Component({
  selector: 'base-grid-action',
  templateUrl: './base-grid-action.component.html',
  styleUrls: ['./base-grid-action.component.scss']
})
export class BaseGridAction<T extends IdData> implements OnInit {

  @Input('dataSource') public dataSource: MatTableDataSource<T>;
  @Input('tableConfig') public tableConfig: TableColDef[];
  @Input('exporter') public exporter: any;

  constructor() {
    // no implementation
   }

  ngOnInit(): void {
      // no implementation
   }

  tableColumns(): string[] {
    return this.tableConfig.map(tableColDef => tableColDef.field);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  exportToXml() {
    const xmlString = CommonUtilityService.convertToXml(this.dataSource.data);
    CommonUtilityService.exportToFile(xmlString, "xml", "data");
  }


  exportToSql() {
    const dataColumns = this.tableConfig.filter(config => !config.ignoreForFileExport).map(config => config.field);
    CommonUtilityService.exportToSql("PartInfo", this.dataSource, dataColumns);
  }

}
