import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { LoaderService } from '../loader.service';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, retry } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  userDetails: any;
  constructor(
    private loader: LoaderService,
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.method === 'POST' || request.method === 'PUT') {
      this.shiftDates(request.body);
    }
     if(window.location.href.split('?')?.[0]?.split('/').pop() != 'login'){
       this.loader.isLoading.next(true);
       
    }
    return next.handle(request).pipe(
      
      catchError((err: HttpErrorResponse) => {
        if (err.status === 0) {
          retry(2);
        }
        return throwError(err);
      }),

      finalize(() => this.loader.isLoading.next(false))
    );
  }

  shiftDates(body) {
    if (body === null || body === undefined) {
        return body;
    }

    if (typeof body !== 'object') {
        return body;
    }

    for (const key of Object.keys(body)) {
        const value = body[key];
        if (value instanceof Date) {
            body[key] = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate(), value.getHours(), value.getMinutes()
                , value.getSeconds()));
        } else if (typeof value === 'object') {
            this.shiftDates(value);
        }
    }
  }
}
