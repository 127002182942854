import { Directive, ElementRef, OnInit, Input, ChangeDetectorRef, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[accessControl]',
    exportAs: 'appHideMe'
})
export class AccessControlDirective implements OnInit {

    @Input() featureCode: any
    @Input() accessType: any
    showLabel: boolean = true
    accesslevels = JSON.parse(localStorage.getItem('feature'))

    constructor(private elementRef: ElementRef, private cdr: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.featureCode && this.checkAccess(this.accessType)
    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes['featureCode']) {
            this.featureCode && this.checkAccess(this.accessType)
        }
    }
    ngAfterViewInit() {
        this.showLabel = false;
        this.featureCode && this.checkAccess(this.accessType)
    }
    checkAccess(role: any) {
        this.elementRef.nativeElement.style.display = 'block'
        if (typeof (this.featureCode) == 'string') {
            this.featureCode = this.featureCode.replace(/'/g, '"')
            this.featureCode = JSON.parse(this.featureCode);
            this.accessType = this.accessType.replace(/'/g, '"')
            this.accessType = JSON.parse(this.accessType);
        }
        if (this.accesslevels?.filter(accesslevel => this.featureCode?.some(i => i === String(accesslevel?.menuId)))?.length > 0) {
            if (this.accessType?.find(tt => tt == this.accesslevels?.filter(accesslevel => this.featureCode?.some(i => i === String(accesslevel?.menuId)))?.[0]?.['permission'])) {
                this.showLabel = true
                this.elementRef.nativeElement.style.display = 'block'
            }
            else {
                this.showLabel = false
                this.elementRef.nativeElement.style.display = 'none'
            }
        }
        else {
            this.showLabel = false
            this.elementRef.nativeElement.style.display = 'none'
        }
        return this.showLabel
    }
}