import { Injectable } from '@angular/core';

@Injectable()
export class SharingService {
  private sharingObject: any;
  constructor() { }

  getSharingValue() {
    return this.sharingObject
  }

  setSharingValue(obj) {
    this.sharingObject = obj;
  }
}