import { Pipe, PipeTransform } from '@angular/core';
import { GlobalConstants } from '../service/constants/GlobalConstants';

@Pipe({
  name: 'limitLength'
})
export class LimitLengthPipe implements PipeTransform {
  _gc = GlobalConstants
  transform(value: any, length?:number): string {
    if(!value || value.toString().length === 0 || value == "null" || value == "undefined")
     return ''
    let strLength:number
    if(!length || length === 0 )
      strLength = 15
    else
      strLength = length
    return value.toString().length > strLength ? `${value.slice(0,strLength)}${this._gc.THREE_DOTS}` : value.toString()
  }

}
