import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'allsearchPipe'
})
export class AllsearchPipe implements PipeTransform{

  transform(collection: any[], term,length?:number ):any[] {
    if(!term){  
      return collection.slice(0,length);
    }

    if(collection && term){
      return collection.filter((item) => JSON.stringify(item).toLowerCase().includes(term.toString().toLowerCase()));

    }
    else{
 
      return [];
    }
    
  }

}