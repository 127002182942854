import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'selectsearchfilter'
})
export class SelectsearchfilterPipe implements PipeTransform {

  transform(items: any[], field: string, value: string): any[] {
    if (!items) return [];
    return items.filter(it => it[field].toLowerCase().includes(value));
  }

  // transform(collection: any[], term):any[] {
   
  //   if(!term){
  //     return collection.slice(1,5);
  //   }

  //   if(collection && term){
      
  //     return collection.filter((item) => JSON.stringify(item).toLowerCase().includes(term.toString().toLowerCase()));

  //   }
  //   else{
 
  //     return [];
  //   }
    
  // }

}
