import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchfilter'
})
export class StartwithPipe implements PipeTransform {

  transform(collection: any[], term):any[] {
  
    if(term=='' || !term){
      return collection;
    }

    if(collection && term){
      
      return collection.filter((item) => JSON.stringify(item).toLowerCase().includes(term.toString().toLowerCase()));

    }
    else
    return collection;
  }
}
