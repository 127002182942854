import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { js2xml } from 'xml-js';

@Injectable({
  providedIn: 'root'
})
export class CommonUtilityService {

  range(start, stop, step) {
    return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
  }

  generateRandomNumber(upperBound=1000) {
    return Math.floor(Math.random() * upperBound);
  }

  getRandomDate() {
    const startDate = new Date(1900, 0, 1); // Start date
    const endDate = new Date(); // Today's date
    const randomDate = new Date(startDate.getTime() + Math.random() * (endDate.getTime() - startDate.getTime())); // Random date between start and end dates
    randomDate.setHours(0, 0, 0, 0); // Set time to midnight
    const formattedDate = randomDate.toISOString().substring(0, 10); // Format date as dd-mm-yyyy
    return formattedDate;
  }

  static convertToXml(data: any): string {
    const options = { compact: true };
    const result = js2xml(data, options);
    return result;
  }

  static exportToFile(data: string, fileFormat: string, fileName: string) {
    const blob = new Blob([data], { type: `text/${fileFormat}` });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.${fileFormat}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  static exportToSql(tableName: string, dataSource: MatTableDataSource<any>, columnNames: string[]) {

    let rowNames = "";
    for (let j = 0; j < columnNames.length; j++) {
      rowNames += `${columnNames[j]}`;
      if (j !== columnNames.length - 1) {
        rowNames += ",";
      }
    }

    let insertQuery = `INSERT INTO ${tableName} (${rowNames}) VALUES`;

    for (let i = 0; i < dataSource.filteredData.length; i++) {
      const row = dataSource.filteredData[i];
      let rowValues = "";
      for (let j = 0; j < columnNames.length; j++) {
        rowValues += row[columnNames[j]];
        if (j !== columnNames.length - 1) {
          rowValues += ",";
        }
      }

      const values = `(${rowValues})`;

      insertQuery += values;

      // add a comma between values, except for the last one
      if (i < dataSource.filteredData.length - 1) {
        insertQuery += ', ';
      }
    }

    CommonUtilityService.exportToFile(insertQuery, "sql", "insert-script");
  }

  initializeIfNotPresent(lsKey: string, data) {
    
    // const exisitngData = JSON.parse(localStorage.getItem(lsKey));

    // if (!exisitngData) {
    //   localStorage.setItem(lsKey, JSON.stringify(data));
    // }
    localStorage.setItem(lsKey, JSON.stringify(data));
  }

  uniqByReduce<T>(array: T[]): T[] {
    return array.reduce((acc: T[], cur: T) => {
        if (!acc.includes(cur)) {
            acc.push(cur);
        }
        return acc;
    }, [])
}

}
