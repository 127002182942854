<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">
      <img src="assets/images/logo.png">

    </a>
  </div>

</div>
<div class="header-container onlyheader">
  <form [formGroup]="userForm">
    <nb-select fullWidth formControlName="branch" (selectedChange)="onChangeBranch($event)">
      <nb-option *ngFor="let item of getbrnach()" [value]="item?.id">{{item?.alias}}</nb-option>
    </nb-select>
  </form>
  <div class="user-profile-design ml-2">
    <div [matMenuTriggerFor]="profileMenu" class="header-log">
      {{ this.userdata?.name?.[0] | titlecase
      }}{{ this.userdata?.name?.[0] | titlecase }}
    </div>
  </div>
  <!-- <nb-actions size="small">
    <nb-action class="control-item">
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action>
    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="Username_branchName"
        [picture]="user?.picture">
      </nb-user>
    </nb-action>
  </nb-actions> -->
</div>
<mat-menu #profileMenu="matMenu">
  <div class="wrap-menu-popup">
    <div class="logo-area">
      {{ this.userdata?.name?.[0] | titlecase
      }}{{ this.userdata?.name?.[0] | titlecase }}
    </div>
    <div class="user-name">
      {{ this.userdata?.name | titlecase }}
    </div>
    <div class="primary-outline-small-button button-area">
      <button  class="materialButton noFillSaveButton" (click)="onContecxtItemSelection('Log out')">Logout</button>
      <button class="materialButton noFillSaveButton" >Profile</button>
    </div>
  </div>
</mat-menu>