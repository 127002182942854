import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";
import { SelectsearchfilterPipe } from './selectsearchfilter.pipe';
import { StartwithPipe } from './startwith.pipe';
import { LimitLengthPipe } from './limitLength.pipe';
import { AllsearchPipe } from './allsearch.pipe';


@NgModule({
  declarations:[SelectsearchfilterPipe,AllsearchPipe, StartwithPipe,LimitLengthPipe], // <---
  imports:[CommonModule],
  exports:[SelectsearchfilterPipe,AllsearchPipe, StartwithPipe,LimitLengthPipe],
  providers:[StartwithPipe] // <---
})

export class MainPipe{}