import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { MatDialogConfig } from '@angular/material/dialog';
import { FormArray, FormGroup } from '@angular/forms';
import { REGEX } from '../pages/service/constants/REGEX';


@Injectable({
    providedIn: 'root'
})
export class GlobalService {
    userDetails: any
    regex=REGEX
    constructor(
        private httpClient: HttpClient,

    ) { }

    filterAllData(type: string, payload): Observable<any> {
        return this.httpClient.get(`${environment.apiBaseUrl}${type}`, payload)
    }
    post(url: string, data?: any, withClientKey: boolean = false): Observable<any> {
        return this.httpClient.post(`${environment.apiBaseUrl}${url}`, data);
    };
    update(url: string, data?: any, withClientKey: boolean = false) {
        return this.httpClient.put(`${environment.apiBaseUrl}${url}`, data);
    };
    delete(url: string, data?: any, withClientKey: boolean = false) {
        return this.httpClient.delete(`${environment.apiBaseUrl}${url}`, data);
    };
    public static readonly CONFIRMATION_MODAL_CONFIG : MatDialogConfig = {
        disableClose : true,
        hasBackdrop : true,
        maxWidth : "450px",
        height : "auto",
        autoFocus : false,
        panelClass : "globalConfirmationModal"
    }
    getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }
    Logout() {
        localStorage.clear();
    }
    public getSessionData() {
        this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
        return this.userDetails;
    }
    getFormFieldErrorMessage(formGroup?: FormGroup, formControlName?: string): string {
        let errorMsg = ''
        if (formControlName === undefined || formGroup?.controls[formControlName] === undefined || formGroup?.controls[formControlName] === null) {
          return ''
        }
        if (formGroup.controls[formControlName].hasError('password_not_match')) {
          errorMsg = "The password confirmation does not match"
        }
        if (formGroup.controls[formControlName].hasError('required')) {
          errorMsg = "Field is required"
        }
        if (formGroup.controls[formControlName].hasError('duplicate')) {
          errorMsg = "This value is already in use."
        }
        if (formGroup.controls[formControlName].hasError('pattern')) {
          const pattern = (formGroup.controls[formControlName].errors['pattern'].requiredPattern);
          const regexObject = this.regex.ALL_REGEXP.find(regExp => (String(regExp.REG_EXP) === String(pattern)))
          errorMsg = `Invalid - ${regexObject?.ERROR_MSG}`
        }
        if (formGroup.controls[formControlName].hasError('email')) {
          errorMsg = "Email is Invalid"
        }
        if (formGroup.controls[formControlName].hasError('maxlength')) {
          const requiredLength = (formGroup.controls[formControlName].errors['maxlength'].requiredLength);
          errorMsg = `Max length (${requiredLength}) exceeded.`
        }
        if (formGroup.controls[formControlName].hasError('minlength')) {
          const requiredLength = (formGroup.controls[formControlName].errors['minlength'].requiredLength);
          errorMsg = `Minimum length is (${requiredLength}).`
        }
        if (formGroup.controls[formControlName].hasError('max')) {
          const requiredLength = (formGroup.controls[formControlName].errors['max'].max);
          errorMsg = `Max (${requiredLength}) value exceeded.`
        }
        if (formGroup.controls[formControlName].hasError('min')) {
          const requiredLength = (formGroup.controls[formControlName].errors['min'].min);
          errorMsg = `Minimum value is (${requiredLength}).`
        }
        if (formGroup.controls[formControlName].hasError('invalidHours')) {
          errorMsg = `Please enter valid time ex; time between 09:00 - 5:00 !`
        }
        if (formGroup.controls[formControlName].hasError('matDatepickerMin')) {
          const requiredLength = formGroup.controls[formControlName].errors['matDatepickerMin'].min
          errorMsg = `Minmum from date is(${requiredLength})`
        }
        return errorMsg
      }
    
      getFormArrayErrorMessages(formGroup: FormGroup, formArrayName: string, formControlName: string, index: number) {
        let errorMsg = ''
        if (formControlName === undefined || formGroup.controls[formArrayName]['controls'][index].controls[formControlName] === undefined || formGroup.controls[formArrayName]['controls'][index].controls[formControlName] === null) {
          return ''
        }
        if (formGroup.controls[formArrayName]['controls'][index].controls[formControlName].hasError('required')) {
          errorMsg = "This Field is required"
        }
        if (formGroup.controls[formArrayName]['controls'][index].controls[formControlName].hasError('pattern')) {
          const pattern = (formGroup.controls[formArrayName]['controls'][index].controls[formControlName].errors['pattern'].requiredPattern);
          const regexObject = this.regex.ALL_REGEXP.find(regExp => (String(regExp.REG_EXP) === String(pattern)))
          errorMsg = `Invalid - ${regexObject?.ERROR_MSG}`
        }
        if (formGroup.controls[formArrayName]['controls'][index].controls[formControlName].hasError('email')) {
          errorMsg = "Email is Invalid"
        }
        if (formGroup.controls[formArrayName]['controls'][index].controls[formControlName].hasError('password_not_match')) {
          errorMsg = "The password confirmation does not match"
        }
        if (formGroup.controls[formArrayName]['controls'][index].controls[formControlName].hasError('maxlength')) {
          const requiredLength = (formGroup.controls[formArrayName]['controls'][index].controls[formControlName].errors['maxlength'].requiredLength);
          errorMsg = `Max length (${requiredLength}) exceeded.`
        }
        if (formGroup.controls[formArrayName]['controls'][index].controls[formControlName].hasError('minlength')) {
          const requiredLength = (formGroup.controls[formArrayName]['controls'][index].controls[formControlName].errors['minlength'].requiredLength);
          errorMsg = `Minimum length is (${requiredLength}).`
        }
        if (formGroup.controls[formArrayName]['controls'][index].controls[formControlName].hasError('max')) {
          const requiredLength = (formGroup.controls[formArrayName]['controls'][index].controls[formControlName].errors['max'].max);
          errorMsg = `Max (${requiredLength}) value exceeded.`
        }
        if (formGroup.controls[formArrayName]['controls'][index].controls[formControlName].hasError('min')) {
          const requiredLength = (formGroup.controls[formArrayName]['controls'][index].controls[formControlName].errors['min'].min);
          errorMsg = `Minimum value is (${requiredLength}).`
        }
        return errorMsg
      }
      getFormArrayError(formArray: FormArray, formControlName: string): Array<any> {
        let errorMsg = []
        let arrayForm = []
        arrayForm = [formArray.controls]
        for (let i = 0; i < arrayForm[0].length; i++) {
          if (formArray.controls[i].get(formControlName).hasError('required')) {
            errorMsg.push({ [i]: `This Field is required` })
          }
          if (formArray.controls[i].get(formControlName).hasError('maxlength')) {
            errorMsg.push({ [i]: `Max length ${formArray.controls[i].get(formControlName).errors['maxlength'].requiredLength}  exceeded.` })
          }
          if (formArray.controls[i].get(formControlName).hasError('pattern')) {
            const pattern = formArray.controls[i].get(formControlName).errors['pattern'].requiredPattern
            const regexObject = this.regex.ALL_REGEXP.find(regExp => (String(regExp.REG_EXP) === String(pattern)))
            errorMsg.push({ [i]: `Invalid - ${regexObject.ERROR_MSG}` })
          }
          if (formArray.controls[i].get(formControlName).hasError('email')) {
            errorMsg.push({ [i]: "Email is Invalid" })
          }
       if (formArray.controls[i].get(formControlName).hasError('minlength')) {
            const requiredLength = (formArray.controls[i].get(formControlName).errors['minlength'].requiredLength);
            errorMsg.push({ [i]: `Minimum length is (${requiredLength}).` })
          }
          if (formArray.controls[i].get(formControlName).hasError('max')) {
            const requiredLength = (formArray.controls[i].get(formControlName).errors['max'].max);
            errorMsg.push(`Max (${requiredLength}) value exceeded.`)
          }
          if (formArray.controls[i].get(formControlName).hasError('min')) {
            const requiredLength = (formArray.controls[i].get(formControlName).errors['min'].min);
            errorMsg.push(`Minimum value is (${requiredLength}).`)
          }
        }
        return errorMsg
      }
}