<div class="add-info-form form-wrapper">
  <h1 class="form-header">{{ editMode ? 'Edit' : 'Add' }} {{ pageHeader }}</h1>
  <form [formGroup]="entryForm" (ngSubmit)="formSubmit()">

    <ng-container *ngFor="let config of formConfigs; trackBy: trackByFn">
        <div *ngIf="config.type === 'dropdown'" class="input-group-wrapper">
          <mat-form-field appearance="outline">
            <mat-label>{{ config.label }}</mat-label>
            <mat-select [formControlName]="config.name">
              <mat-option *ngFor="let val of config.initialValue" [value]="val.id">{{val.value}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="config.type === 'text'" class="input-group-wrapper">
          <mat-form-field appearance="outline">
            <mat-label>{{ config.label }}</mat-label>
            <input matInput [placeholder]="config.label" [formControlName]="config.name" [disabled]="config.disabled">
            <mat-error *ngIf="entryForm.controls[config.name].errors &&
                    entryForm.controls[config.name].hasError('required')">
              {{ config.label }} is required
            </mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="config.type === 'number'" class="input-group-wrapper">
          <mat-form-field appearance="outline">
            <mat-label>{{ config.label }}</mat-label>
            <input type="number" matInput [placeholder]="config.label" [formControlName]="config.name" [disabled]="config.disabled">
            <mat-error *ngIf="entryForm.controls[config.name].errors &&
                    entryForm.controls[config.name].hasError('required')">
              {{ config.label }} is required
            </mat-error>
          </mat-form-field>
        </div>

        <div *ngIf="config.type === 'date'" class="input-group-wrapper">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{ config.label }}</mat-label>
            <input matInput [matDatepicker]="picker" [formControlName]="config.name">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="input-group-wrapper" *ngIf="config.type === 'fileupload'">
          <div class="file-upload-input">
            <label for="file-upload">{{ config.label }}</label>
            <input id="file-upload" name="file-upload" type="file" (change)="fileUpload($event)">
          </div>
        </div>

        <div class="cs-form" *ngIf="config.type === 'time'">
          <label>{{ config.label }}</label>
          <input type="time" name="time" class="form-control" [formControlName]="config.name" />
        </div>
    </ng-container>

    <div class="input-group-wrapper">
      <button class="form-submit-btn" mat-raised-button color="primary" [disabled]="!entryForm.valid"
        type="submit">Submit</button>
    </div>
  </form>
</div>
