import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GlobalService } from '../../../services/global.service';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  branchIds=[]
  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';
  userdata:any;
  Username_branchName:any;
  userMenu = [  { title: 'Log out' } ];
  branches=[];
  userForm: FormGroup;
  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private globalService:GlobalService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private GlobalService:GlobalService,
              private router: Router,
              private fb: FormBuilder,
              private breakpointService: NbMediaBreakpointsService) {
                
            this.menuService.onItemClick()
            .subscribe((event) => {
              this.onContecxtItemSelection(event.item.title);
            });
            this.userdata=this.GlobalService.getSessionData();
            this.userForm = this.fb.group({
              branch: new FormControl(this.userdata?.branchId ? Number(this.userdata?.branchId) :"", Validators.required),
            });
            this.Username_branchName=this.userdata?.name;
  }
  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    this.getBranchList();
    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }
  onContecxtItemSelection(title) {
    if(title === 'Log out'){
        this.GlobalService.Logout();
        // this.router.navigate(['#','login']);
        location.reload()
    }
  }
 getBranchList(){
      this.globalService.filterAllData('branch', {}).subscribe((res) => {
        if (res) {
          this.branches = res;
          let branchName=this.branches?.find(br=>br?.id == this.userdata?.branchId);
          this.userForm.patchValue({branch:Number(this.userdata?.branchId)});
          if(branchName?.branchName) this.Username_branchName=this.userdata?.name+" - "+branchName?.branchName;
          this.setbranch();
        }
      }, (error) => { })
    }
  getbrnach(){
    return this.branches?.filter(br=>this.branchIds?.find(r=>r== br?.id));
  }
  setbranch(){
      this.globalService.filterAllData('user_branch/' +this.userdata?.id, {}).subscribe((res_branch) => {
        if (res_branch) {
          this.branchIds=res_branch?.map(re=>re?.branchId);
        }
      }, (error) => { })
    }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  onChangeBranch(event){
    localStorage.setItem('userDetails', JSON.stringify({...this.userdata,branchId:String(event)}));
    location.reload();
  }
  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }
  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
