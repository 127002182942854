import { MatDialogConfig } from "@angular/material/dialog";
import { MatSnackBarConfig } from "@angular/material/snack-bar";

export class GlobalConstants {
    public static readonly DIABOS_USER_NAME :string = "DIABOS";
    public static readonly OPERATOR_USER_NAME :string = "OPERATOR";
    public static readonly THREE_DOTS :string = "...";
    public static readonly LOADER_TEXT :string = `Loading${this.THREE_DOTS}Please Wait`;
    public static readonly NDF :string = "No data found";
    public static  NO_DATA_FOUND_FOR_SEARCH_INPUT :string = "No data found";
    public static readonly NO_DATA_FOUND :string = "No data found";
    public static readonly EXPAND_ICON :string = "control_point";
    public static readonly COLLAPSE_ICON :string = "remove_circle_outline";
    public static readonly SOMETHING_WENT_WRONG :string = "Oops..! Something went wrong, please try again later.";
    public static readonly FILE_SIZE:string = "File Size Exceeds 25 MB"
    public static readonly TIMEOUT = true;
    public static readonly FORM_CLOSE_WARN_MESSAGE = "There are unsaved changes, would you like to continue?"
    public static readonly NODATA = "Data not found"
    public static readonly NONOTIFICATIONS = "No Notification found!"
    //PAGINATION CONSTANTS
        public static readonly LESS_PAGINATOR_OPTIONS :number[] = [ 2, 4, 6, 8 ];
        public static readonly PAGINATOR_OPTIONS :number[] = [ 20 ,50, 100 ];
    //PAGINATION CONSTANTS

    //SNACKTOASTER CONSTANTS
        public static readonly DEFAULT_ERROR_MESSAGE :string = "Error";
        public static readonly DEFAULT_INFO_MESSAGE :string = "Info";
        public static readonly DEAFULT_SNACK_TOASTER_TIMER :number = 3000;
        public static readonly DEFAULT_SUCCESS_MESSAGE :string = "Success";
        public static readonly SNACK_TOASTER_INFO :string = "snackToaster_info";
        public static readonly DEFAULT_SNACK_TOASTER_CLASS :string = "snackToaster_info";
        public static readonly SNACK_TOASTER_ERROR :string = "snackToaster_error";
        public static readonly SNACK_TOASTER_WARN :string = "snackToaster_warn";
        public static readonly SNACK_TOASTER_SUCCESS :string = "snackToaster_success";
        public static readonly SNACK_TOASTER_CONFIG :MatSnackBarConfig ={
            politeness : "polite",
            direction : "ltr",
            verticalPosition : 'top',
            horizontalPosition : 'right'
        };
    //SNACKTOASTER CONSTANTS

    //MATERIAL MODAL CONFIGS
        public static readonly SMALL_MODAL_CONFIG : MatDialogConfig ={
            hasBackdrop : true,
            disableClose : true,
            width : '35vw',
            maxHeight : '90vh',
            autoFocus : false
        }
        public static readonly CONFIRMATION_MODAL_CONFIG : MatDialogConfig = {
            disableClose : true,
            hasBackdrop : true,
            maxWidth : "450px",
            height : "auto",
            autoFocus : false,
            panelClass : "globalConfirmationModal"
        }
        public static readonly CONFIRMATION_MODAL_CONFIG_WITH_REMARKS : MatDialogConfig = {
            disableClose : true,
            hasBackdrop : true,
            maxWidth : "450px",
            height : "auto",
            autoFocus : false,
            panelClass : "globalConfirmationModal"
        }
        public static readonly MEDIUM_MODAL_CONFIG : MatDialogConfig = {
            disableClose : true,
            hasBackdrop : true,
            maxWidth : "680px",
            height : "auto",
            autoFocus : false
        }
        public static readonly MEDIUM_WIDTH_MODAL_CONFIG : MatDialogConfig = {
            disableClose : true,
            hasBackdrop : true,
            width : "490px",
            height : "auto",
            autoFocus : false
        }
        public static readonly MEDIUM_WIDTH_MODAL_CONFIG_PX : MatDialogConfig = {
            disableClose : true,
            hasBackdrop : true,
            width : "560px",
            height : "auto",
            autoFocus : false
        }

        public static readonly MODAL_CONFIG : MatDialogConfig = {
            disableClose : true,
            hasBackdrop : true,
            autoFocus : false
        }
        public static readonly LARGE_MODAL_CONFIG : MatDialogConfig = {
            disableClose : true,
            hasBackdrop : true,
            maxWidth : "680px",
            maxHeight : "100vh",
            autoFocus : false
        }

        public static readonly FULL_MODAL_CONFIG : MatDialogConfig = {
            disableClose : true,
            hasBackdrop : true,
            maxWidth : "100%",
            maxHeight : "100vh",
            autoFocus : false
        }

        public static readonly MODAL_CONFIG_CLOSE : MatDialogConfig = {
            disableClose : false,
            hasBackdrop : true,
            autoFocus : false
        }
    //MATERIAL MODAL CONFIGS

    //MONTHS & DAYS
        public static readonly MONTHS_LIST :string[] = ['January','February','March','April','May','June','July','August','September','October','November','December'];
        public static readonly MONTHS_SHORT_LIST :string[] = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        public static readonly DAY_LIST :string[]=['Value Date','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday']
        public static readonly DAY_SHORT_LIST :string[]=['Mon','Tue','Wed','Thu','Fri','Sat','Sun']
    //MONTHS & DAYS

 
}