import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormRowDef, IdData } from '../../../pages/models/common.model';

@Component({
  selector: 'base-entry-form',
  templateUrl: './base-entry-form.component.html',
  styleUrls: ['./base-entry-form.component.scss']
})
export class BaseEntryForm<T extends IdData> {

  @Input('entryForm') public entryForm: FormGroup;
  @Input('editMode') public editMode: string;
  @Input('pageHeader') public pageHeader: string;
  @Input('formConfigs') public formConfigs: FormRowDef[];
  @Output('onFormSubmit') public onFormSubmit = new EventEmitter();
  @Output('onFileUpload') public onFileUpload = new EventEmitter();


  constructor() {
    // no implementation
  }

  trackByFn(index: number, field: any) {
    return field.id;
  }


  formSubmit() {
    this.onFormSubmit.emit();
  }


  fileUpload() {
    this.onFileUpload.emit();
  }

}
