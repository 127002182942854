import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule as ngFormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCommonModule } from './mat-common.module';
import { BaseEntryForm } from './share-components/base-entry-form/base-entry-form.component';
import { BaseGridAction } from './share-components/base-grid-action/base-grid-action.component';
import { MainPipe } from '../pages/pipe/main-pipe.module';
import { DirectivesModule } from './directives/directives.module';


@NgModule({
  imports: [
    MatCommonModule,
    ReactiveFormsModule,
    CommonModule,
    ngFormsModule,
    MainPipe,
    DirectivesModule,

  ],
  exports: [
    MatCommonModule,
    ReactiveFormsModule,
    BaseGridAction,
    BaseEntryForm,
    MainPipe,
    DirectivesModule
  ],
  declarations: [BaseGridAction, BaseEntryForm],
})
export class AppCommonModule {  }
